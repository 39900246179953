import React, { useEffect, useState } from "react";
import style from "./PopUpComponent.module.css"
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getPopUpProperties } from "../../Services/actions";
const url = process.env.REACT_APP_API_ADMIN;



export default function BreadCrumb() {
  const { propertiesPopUp } = useSelector(s => s)
  const { catalogo } = useSelector((s) => s);
  const currentDate = new Date();
  let isWithinValidity = false;

  let catalogoSorted = catalogo.sort(
    (a, b) => b.prioridad - a.prioridad
  ).filter((arr) => arr.isBestSelling == false)[0];
  const imagenPopup = catalogoSorted?.popUpCcommerce?.media?.url;
  const redirectUrl = catalogoSorted?.popUpCcommerce?.redirectUrl;
  let validityTo = catalogoSorted?.popUpCcommerce?.fechaHasta;


  useEffect(() => {
    
    if (propertiesPopUp) {
      const isPopupEnabled = catalogoSorted?.popUpCcommerce?.enabled;

      const validityFrom = catalogoSorted?.popUpCcommerce?.fechaDesde;
      
      console.log(catalogoSorted)

      if (validityFrom && validityTo) {
        isWithinValidity = validityFrom && validityTo
          ? currentDate >= new Date(validityFrom.split('/').reverse().join('-')) &&
          currentDate <= new Date(validityTo.split('/').reverse().join('-'))
          : true;
      }

      if (document.cookie.includes("PopUp") || !isPopupEnabled || !isWithinValidity) {
        setShow(false);
      } else {
        setShow(true)
      }
    }

  }, [propertiesPopUp,catalogo]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000); // 24 horas en milisegundos
    const expires = `expires=${expirationDate.toUTCString()}`;
    document.cookie = `PopUp=true; ${expires}; path=/`;
    setShow(false)
  };
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className={style.imgContainer}>
            <a href={redirectUrl}>
              <img className={style.imagenPopup} src={imagenPopup} alt=""></img>
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer> <div>Válido hasta: {validityTo}</div> </Modal.Footer>
      </Modal>
    </>
  )
}
